<template>
    <footer class="footer">
        <b-container fluid>
            <b-row>
                <b-col col sm="6">
                    {{ new Date().getFullYear() }} © <em class="text-success"><img :src="$getConfiguration('logo')" alt="" width="50" class="m-0 p-0"></em> - <router-link to="/">Accueil</router-link>
                </b-col>
                <b-col col sm="6" class="text-end">
                    {{ $getConfiguration('app_slogan') }}
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const getConfigurations = computed(() => store.getters['configuration/getConfigurations']);
</script>