import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';
import router from '../../router/index'

export const state = {
  accessToken: null,
  refreshToken: null,
  currentUser: null,
};

export const getters = {
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getCurrentUser: (state) => state.currentUser,
};

export const mutations = {
  setTokens(state, { access, refresh }) {
    state.accessToken = access;
    state.refreshToken = refresh;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    localStorage.setItem('user', true);
  },

  removeTokens(state) {
    state.accessToken = null;
    state.refreshToken = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  },

  setCurrentUser(state, user) {
    state.currentUser = user
  },
};

const dataLogout = {
  refresh_token: localStorage.getItem('refresh_token')
}

export const actions = {
  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'login', loginData)
        .then(response => {
          commit('setTokens', response.data);
          commit('setCurrentUser', response.data.user);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  registerUser({ commit, dispatch, state }, userData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'register', userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  loginWithGoogle({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'auth/google', loginData)
        .then(response => {
          commit('setTokens', response.data);
          commit('setCurrentUser', response.data.user);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  registerWithGoogle({ commit, dispatch, state }, userData) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'register/google', userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  resetPassword({ commit, dispatch, state }, email) {
    return new Promise((resolve, reject) => {
      axios.post(BASE_API_URL + 'reset-password', { email: email })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
          router.push('/login')
        });
    });
  },

  logout({ commit, dispatch, state }, dataLogout) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('logout', dataLogout)
        .then(response => {
          commit('removeTokens');
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response);
          router.push('/login')
        });
    });
  },

  updateUser({ commit, dispatch, state }, userData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/${userData.id}`, userData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('users')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  _currentUser({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('current-user')
        .then(response => {
          commit('setCurrentUser', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchUsersByType({ commit }, filterOptions) {
    let url = `users/by/role/${filterOptions.role}`;
    if(filterOptions.page) url += `?page=${filterOptions.page}`;
    if(filterOptions.search) url += `&search=${filterOptions.search}`;
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updatePassword({ commit, dispatch, state },passwordData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put('update-password/' + passwordData.id, passwordData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateProfile({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-profile-image/${data.get('id')}`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deleteUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete/${userId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchUser({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user-detail/${userId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  getUserPartnerActiveAndFree({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`users_partner_free-is_active`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  setUserStatus({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`users-set-status/${userId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  refreshToken({ commit, state }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('refresh-token', { refresh: state.refreshToken })
        .then(response => {
          commit('setTokens', { access: response.data.access, refresh: state.refreshToken });
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  
};