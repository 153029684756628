import axiosWithHeaders from '../../services/api';

export const state = {
  testimonials: []
};

export const getters = {
  getTestimonials: (state) => state.testimonials,
};

export const mutations = {
  setTestimonials(state, testimonials) {
    state.testimonials = testimonials;
  },
};

export const actions = {
  testimonials({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('testimonials/' + id)
        .then(response => {
          commit('setTestimonials', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  addTestimonial({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`add-testimonial`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateTestimonial({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-testimonial/${data.get('id')}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deleteTestimonial({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete-testimonial/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};
