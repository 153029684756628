import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';

export const state = {
  userServices: [],
};

export const getters = {
  getUserServices: (state) => state.userServices,
};

export const mutations = {
  setUserServices(state, services) {
    state.userServices = services;
  },
};

export const actions = {
  services({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}services`)
        .then(response => {
          commit('setUserServices', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  service({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}service/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },  

  addService({ commit }, serviceData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`add-service`, serviceData)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update-service/${data.get('id')}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deleteService({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete-service/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};
