import axiosWithHeaders from '../../services/api';

export const state = {
  payments: [],
  payment: {}
};

export const getters = {
  getPayments: (state) => state.payments,
  getCurrentPayment: (state) => state.payment
};

export const mutations = {
  setPayments(state, payments) {
    state.payments = payments;
  },
  setCurrentPayment(state, payment) {
    state.payment = payment;
  },
};

export const actions = {
  payments({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('method-payments')
        .then(response => {
          commit('setPayments', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  addPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`method-payments/add`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updatePayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`method-payments/update/${data.get('id')}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deletePayment({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`method-payments/delete/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  paymentByName({ commit }, name) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`method-payment/${name}`)
        .then(response => {
          commit('setCurrentPayment', response.data)
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};
