import AppLayout from '@/layouts/main.vue'
import PublicLayout from '@/layouts/public.vue'
import ShowLayout from '@/layouts/show.vue'
export default [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login",
      authRequired: false,
    },
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "/",
    meta: { authRequired: false },
    component: PublicLayout,
    children: [
      {
        path: "/",
        name: "Home",
        meta: { title: "Dashboard" },
        component: () => import("../views/public/index.vue"),
      },
      {
        path: "/services",
        name: "Services",
        meta: { title: "Services" },
        component: () => import("../views/public/service.vue"),
      },
      {
        path: "/medias",
        name: "Medias",
        meta: { title: "Medias" },
        component: () => import("../views/public/videos.vue"),
      }
    ]
    
  },
  {
    path: "/",
    meta: { authRequired: false },
    component: ShowLayout,
    children: [
      {
        path: "/show-member/:id",
        name: "ShowMember",
        meta: { title: "Détails membre" },
        component: () => import("../views/public/show.vue"),
      }
    ]
    
  },
  {
    path: "/register/customer",
    name: "registerCustomer",
    meta: {
      title: "Customer Register",
      authRequired: false,
    },
    component: () => import("../views/account/register-customer.vue"),
  },
  {
    path: "/register/member",
    name: "registerMember",
    meta: {
      title: "Member Register",
      authRequired: false,
    },
    component: () => import("../views/account/register-member.vue"),
  },
  {
    path: "/forget-pass",
    name: "forget-pass",
    meta: {
      title: "ForgetPass",
      authRequired: false,
    },
    component: () => import("../views/account/forget-pass.vue"),
  },
  {
    path: "/success-reset",
    name: "success-reset",
    meta: {
      title: "SuccessRest",
      authRequired: false,
    },
    component: () => import("../views/account/success-reset.vue"),
  },
  {
    path: "/dashboard",
    meta: { authRequired: true },
    component: AppLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Dashboard" },
        component: () => import("../views/dashboard/index"),
      },
      {
        path: "/reservation",
        name: "reservation",
        meta: { title: "Reservation" },
        component: () => import("../views/layout/reservation.vue"),
      },
      {
        path: "/users-profile/:id",
        name: "users-profil",
        meta: { title: "Profile Utilisateur" },
        component: () => import("@/views/layout/profile-user.vue"),
      },
      {
        path: "/partenaire-profile/:id",
        name: "partenaire-profil",
        meta: { title: "Profile Membre" },
        component: () => import("@/views/layout/profile-member.vue"),
      },
      {
        path: "/user-medias/:id",
        name: "user-medias",
        meta: { title: "Médias" },
        component: () => import("@/views/layout/Member/media.vue"),
      },
      {
        path: "/customer",
        name: "customer",
        meta: { title: "Clients" },
        component: () => import("@/views/layout/Admin/customer.vue"),
      },
      {
        path: "/member",
        name: "member",
        meta: { title: "Membre" },
        component: () => import("@/views/layout/Admin/member.vue"),
      },
      {
        path: "/abonnement",
        name: "abonnement",
        meta: { title: "Abonnements" },
        component: () => import("@/views/layout/Admin/abonnement.vue"),
      },
      {
        path: "/user-abonnement",
        name: "user-abonnement",
        meta: { title: "Abonnements" },
        component: () => import("@/views/layout/Member/abonnement.vue"),
      },
      {
        path: "/member-profil/:id",
        name: "member-profil",
        meta: { title: "Profile Membre" },
        component: () => import("@/views/layout/memberProfile.vue"),
      },
      {
        path: "/setting-generale",
        name: "setting-generale",
        meta: { title: "Paramètres Généraux" },
        component: () => import("@/views/layout/Settings/general.vue"),
      },
      {
        path: "/setting-service",
        name: "setting-service",
        meta: { title: "Paramètres Service" },
        component: () => import("@/views/layout/Settings/service.vue"),
      },
      {
        path: "/setting-question",
        name: "setting-question",
        meta: { title: "Paramètres Question" },
        component: () => import("@/views/layout/Settings/questions.vue"),
      },
      {
        path: "/setting-paiments",
        name: "setting-paiments",
        meta: { title: "Paramètres Paiements" },
        component: () => import("@/views/layout/Settings/payment.vue"),
      },
      {
        path: "/setting-section-image",
        name: "setting-section-image",
        meta: { title: "Section Image" },
        component: () => import("@/views/layout/Settings/section_file.vue"),
      }
    ]
  },
];