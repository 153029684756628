<script>
import {
  layoutComputed
} from "@/state/helpers";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...layoutComputed,
    ...mapGetters('auth', ["getCurrentUser"]),
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if(! this.getCurrentUser){
      await this._currentUser()
    }
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
                siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2) {
                item.firstElementChild.setAttribute("aria-expanded", "false");
                item.firstElementChild.classList.remove("active");
              }
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
                item1.parentElement.firstChild.classList.remove("active");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.classList.remove("show");
            childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
          });
        });
      });
    }
  },

  methods: {
    ...mapActions('auth', ['_currentUser']),
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if (grandparent && grandparent && grandparent.previousElementSibling) {
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <b-container fluid>
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'"></template>

    <template v-else>
      <ul class="navbar-nav h-100" id="navbar-nav">
        <li class="menu-title">
          <span data-key="t-menu"> MENU</span>
        </li>
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link" data-key="t-dashboard">
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-medias">Tableau de bord</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'reservation' }" class="nav-link" data-key="t-reservation">
            <i class="bx bx-list-check"></i>
            <span data-key="t-medias">Reservations</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="getCurrentUser && getCurrentUser.id && (getCurrentUser.role != 'Super Admin' && getCurrentUser.role != 'Customer')">
          <router-link :to="{ name: 'user-abonnement' }" class="nav-link" data-key="t-user-abonnement">
            <i class="las la-handshake"></i>
            <span data-key="t-medias">Abonnements</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="getCurrentUser && getCurrentUser.id && (getCurrentUser.role != 'Super Admin' && getCurrentUser.role != 'Customer')">
          <router-link :to="{ name: 'user-medias', params: { id: getCurrentUser.id } }" class="nav-link" data-key="t-document">
            <i class="las la-photo-video"></i>
            <span data-key="t-medias">Medias</span>
          </router-link>
        </li>
        <li class="nav-item"  v-if="getCurrentUser && getCurrentUser.id && getCurrentUser.role == 'Super Admin'">
          <b-link class="nav-link menu-link" href="#sidebarUser" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarUser">
            <i class="las la-user-friends"></i>
            <span data-key="t-User">Utilisateurs</span>
          </b-link>
          <div class="collapse menu-dropdown" id="sidebarUser">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/member" class="nav-link" data-key="t-member">
                  Membres
                </router-link>
              </li>
              <li class="nav-item active">
                <router-link to="/customer" class="nav-link" data-key="t-customer">
                  Clients
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item" v-if="getCurrentUser && getCurrentUser.id && (getCurrentUser.role == 'Super Admin')">
          <router-link to="/abonnement" class="nav-link" data-key="t-abonnement">
            <i class="las la-handshake"></i>
            <span data-key="t-medias">Abonnements</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Home' }" class="nav-link" data-key="t-reservation">
            <i class="bx bx-group"></i>
            <span data-key="t-medias">Page publique</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="getCurrentUser && getCurrentUser.id && (getCurrentUser.role == 'Super Admin')">
          <b-link href="#sidebarProjects" class="nav-link" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarProjects" data-key="t-projects">
            <i class="mdi mdi-cog-outline"></i>
            <span data-key="t-settings">Paramétres</span>
          </b-link>
          <div class="collapse menu-dropdown" id="sidebarProjects">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/setting-generale" class="nav-link" data-key="t-general">
                  Généraux
                </router-link>
                <router-link to="/setting-section-image" class="nav-link" data-key="t-section-file">
                  Section Images
                </router-link>
                <router-link to="/setting-service" class="nav-link" data-key="t-services">
                  Services
                </router-link>
                <router-link to="/setting-question" class="nav-link" data-key="t-questions">
                  Questions
                </router-link>
                <router-link to="/setting-paiments" class="nav-link" data-key="t-payment">
                  Paiments
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>
  </b-container>
</template>