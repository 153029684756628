import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';

export const state = {
  section_files: []
};

export const getters = {
  getSectionFiles: state => {
    return state.section_files
  }
};

export const mutations = {
  setSectionFile(state, section_files) {
    state.section_files = section_files;
  }
};

export const actions = {
  updateSectionFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/section/file/${data.get('id')}`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchSectionFiles({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}section/files`)
        .then(response => {
          commit('setSectionFile', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  }
};
