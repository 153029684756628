<template>
    <div id="app">
        <Loading v-if="isLoading" />
        <router-view v-else />
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Loading from './views/Loading.vue';

const store = useStore();
const isLoading = computed(() => store.getters['configuration/isLoading']);
const configurations = computed(() => store.getters['configuration/getConfigurations']);

onMounted(async () => {
    if (!configurations.value) {
        try {
            await store.dispatch('configuration/fetchConfigurations');
        } catch (error) {
            console.error('Failed to fetch configurations:', error);
        }
    }
});
</script>