import axiosWithHeaders from '../../services/api';
import store from '../store';

export const state = {
  reservations: [],
  reservation: {}
};

export const getters = {
  getReservations: state => {
    return state.reservations
  },
  getReservation: state => {
    return state.reservation
  }
};

export const mutations = {
  setReservations: (state, reservations) => {
    state.reservations = reservations;
  },
  setReservation: (state, reservation) => {
    state.reservation = reservation
  },
  addReservation: (state, reservation) => {
    state.reservations.unshift(reservation)
  },
};

const formatFilterStat = (filterData)=>{
  let url = `?start_date=${filterData.start_date}&end_date=${filterData.end_date}`;
  if (filterData.user !== undefined && filterData.user !== null) url += `&user=${filterData.user}`;
  if (filterData.customer !== undefined && filterData.customer !== null) url += `&customer=${filterData.customer}`;
  if (filterData.status !== undefined && filterData.status !== null) url += `&status=${filterData.status}`;
  if (filterData.service !== undefined && filterData.service !== null) url += `&service=${filterData.service}`;
  return url
}

export const actions = {
  reservations({ commit }, filterData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('reservations' + formatFilterStat(filterData))
        .then(response => {
          commit('setReservations', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  reservation({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`reservation/${id}`)
      .then(response => {
        commit('setReservation', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },

  storeReservation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post('store/reservation', data)
      .then(response => {
        commit('addReservation', response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },

  updateReservation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/${data.id}/reservation`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },

  setMultipleReservation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set/multiple/reservations/status`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },

  setReservationStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set/reservation/${data.id}/status`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },

  deleteReservation({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete/${id}/reservation`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error?.response?.data);
      });
    });
  },
  payReservation({ commit }, reservationData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`pay/${reservationData.id}/reservation`, reservationData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  refundReservation({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`refund/${id}/reservation`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};
