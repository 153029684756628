import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';

export const state = {
  configurations: null,
  isLoading: false,
  error: null,
};

export const getters = {
  getConfigurations: (state) => state.configurations,
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
};

export const mutations = {
  setConfigurations(state, configurations) {
    state.configurations = configurations;
  },

  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setError(state, error) {
    state.error = error;
  },
};

export const actions = {
  updateConfiguration({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/configuration/${data.id}`, data.data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchConfiguration({ commit }, configId) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}configurations/${configId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  fetchConfigurations({ commit }) {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}configurations`)
        .then(response => {
          commit('setConfigurations', response.data);
          resolve(response.data);
        })
        .catch(error => {
          commit('setError', error?.response?.data);
          reject(error?.response?.data);
        }).finally(() => {
          commit('setLoading', false);
        });
    });
  }
};
