import axiosWithHeaders from '../../services/api';

export const state = {};

export const getters = {};

export const mutations = {};


export const actions = {
  fetchCountries({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get('countries')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  country({commit}, countryId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`countries/${countryId}`)
       .then(response => {
          resolve(response.data);
        })
       .catch(error => {
          reject(error?.response?.data);
        });
    });
  }
};
