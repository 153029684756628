import axios from 'axios';
import axiosWithHeaders from '../../services/api';

export const state = {
  homeStats: null
};

export const getters = {
  getHomeStats: state => state.homeStats
};

export const mutations = {
  setHomeStats(state, member) {
    state.homeStats = member;
  }
};

export const actions = {
  homeStats({ commit }, options) {
    let url = `home-stats?start_date=${options.start_date}&end_date=${options.end_date}&user=${options.user}&customer=${options.customer}&service=${options.service}`;
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(url)
        .then(response => {
          commit('setHomeStats', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};