<!-- src/components/LoadingPage.vue -->
<template>
    <div class="loading-page">
        <p>Chargement en cours...</p>
    </div>
</template>

<script setup>
</script>

<style scoped>
.loading-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 24px;
    font-weight: bold;
}
</style>