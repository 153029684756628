import axios from 'axios';
import axiosWithHeaders from '../../services/api';

export const state = {
  memberDetail: null
};

export const getters = {
  getMemberDetail: state => state.memberDetail
};

export const mutations = {
  setMemberDetail(state, member) {
    state.memberDetail = member;
  }
};

export const actions = {
  member({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`member`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateMember({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/user/${data.user}/member-info`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateUserService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/user/${data.user}/service`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  createUserService({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`create/user/${data.user}/service`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deleteUserService({ commit }, user_service) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete/user-service/${user_service}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  
  userMember({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user-member/${id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  userMembers({ commit }, filterOptions) {
    return new Promise((resolve, reject) => {
      let url = `user-members`;
      if(filterOptions.page) url += `?page=${filterOptions.page}`;
      if(filterOptions.status) url += `&status=${filterOptions.status}`;
      if(filterOptions.search) url += `&search=${filterOptions.search}`;
      if(filterOptions.country) url += `&country=${filterOptions.country}`;

      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateMemberStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/user-member/${data.id}/status`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  updateMultipleMemberStatus({ commit }, { status, ids }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`update/multiple/user-member/status`, { status, ids })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  publicMembers({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `public-members`;
      if(data){
        if(data.service) url += `?service=${data.service}&search=${data.search}&country=${data.country}`;
      }
      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  publicStats({ commit }, data) {
    return new Promise((resolve, reject) => {
      let url = `public-stats`;
      axiosWithHeaders.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  publicMember({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`public-member-detail/${id}`)
        .then(response => {
          commit('setMemberDetail', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  userCustomerAndRole({ commit }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`user/customer/role`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  medias({ commit }, search) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`medias-publish?search=${search}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  sendMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`send-message`, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};