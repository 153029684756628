import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';


export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  subscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${BASE_API_URL}subscriptions`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },
  
    updateSubscription({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.put(`update/subscriptions/${id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },

    userSubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get('user/subscriptions')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },
  
    createUserSubscription({ commit }, data) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(`create/user/subscription/${data.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },

    mySubscriptions({ commit }) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.get('my/subscription')
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },

    deleteUserSubscription({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.delete(`delete/user/subscription/${id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },

    setDefaultSubscription({ commit }, id) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(`set/default/subscription/${id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },

    paySubscription({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosWithHeaders.post(`pay/subscription/${payload.id}`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error?.response?.data);
          });
      });
    },
    
  };
