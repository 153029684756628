import axios from 'axios';
import axiosWithHeaders from '../../services/api';

export const state = {
};

export const getters = {
};

export const mutations = {
};

export const actions = {
  memberMedias({ commit }, memberId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.get(`member-medias/${memberId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  
  createMemberMedia({ commit }, formData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`create-member-media/${formData.get('id')}`, formData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  deleteMemberMedia({ commit }, mediaId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`delete-member-media/${mediaId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },

  setDefaultMemberMedia({ commit }, mediaId) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`set-member-media-default/${mediaId}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  }
};