import axios from 'axios';
import { BASE_API_URL } from '../../services/api';
import axiosWithHeaders from '../../services/api';


export const state = {
  questions: []
};

export const getters = {
  getQuestions: state => state.questions
};

export const mutations = {
  setQuestions(state, questions) {
    state.questions = questions
  }
};

export const actions = {
  questions({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${BASE_API_URL}questions`)
        .then(response => {
          commit('setQuestions', response.data);
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  addQuestion({ commit }, questionData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.post(`questions/add`, questionData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  updateQuestion({ commit }, questionData) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`questions/update/${questionData.id}`, questionData)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  deleteQuestion({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.delete(`questions/delete/${id}`)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
  setQuestionOnline({ commit }, { id, online }) {
    return new Promise((resolve, reject) => {
      axiosWithHeaders.put(`questions/set-online/${id}`, { online })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data);
        });
    });
  },
};
