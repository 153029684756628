import { createApp } from 'vue';
import App from './App.vue';
import AOS from 'aos';
import router from "./router";
import 'aos/dist/aos.css';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/creative/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import Helper from '@/mixins/Helper'
import jQuery from 'jquery'

window.$ = jQuery
global.$ = jQuery

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App);
app.use(store);
app.use(router);
app.mixin(Helper);
app.use(VueApexCharts);
app.use(BootstrapVue3);
app.component(VueFeather.type, VueFeather);
app.use(Maska);
app.use(vClickOutside);
app.mount('#app');