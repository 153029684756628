<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const getCurrentUser = computed(() => store.getters['auth/getCurrentUser']);
const getConfigurations = computed(() => store.getters['configuration/getConfigurations']);

const loadData = async () => {
  await store.dispatch('auth/_currentUser');
  await store.dispatch('member/publicMembers');
}

onMounted(async () => {
  if (!getCurrentUser.value) {
    loadData()
  }
});
</script>

<template>
    <div class="layout-wrapper landing">
        <nav class="navbar navbar-expand-lg navbar-landing navbar-light fixed-top" id="navbar">
            <b-container>
                <b-link class="navbar-brand" href="/">
                    <img :src="$getConfiguration('logo')" alt="" width="100" class="m-0 p-0">
                </b-link>
                <button class="navbar-toggler py-0 fs-20 text-body" type="button" v-b-toggle.navbarSupportedContent>
                    <i class="mdi mdi-menu"></i>
                </button>

                <b-collapse class="navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link fs-15">Accueil</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/medias" class="nav-link fs-15">Postes</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/services" class="nav-link fs-15">A propos</router-link>
                        </li>
                        <li class="nav-item" v-if="getCurrentUser">
                            <router-link to="/dashboard" class="nav-link fs-15">Tableau de bord</router-link>
                        </li>
                    </ul>

                    <div class="" v-if="!getCurrentUser">
                        <router-link to="/login"
                            class="btn btn-outline-success me-2 fw-medium">Connexion</router-link>
                        <router-link to="/register" class="btn btn-outline-info">Inscription</router-link>
                    </div>
                </b-collapse>

            </b-container>
        </nav>
        <div class="bg-overlay bg-overlay-pattern"></div>
        <div>
            <router-view></router-view>
        </div>
        <footer class="custom-footer bg-dark py-5 position-relative">
            <b-container>
                <b-row class="text-center text-sm-start align-items-center mt-5">
                    <b-col sm="6">

                        <div>
                            <p class="copy-rights mb-0 currentyear">{{ new Date().getFullYear() }} © <em class="text-success">{{ $getConfiguration('app_name') }}</em>
                            </p>
                        </div>
                    </b-col>
                    <b-col sm="6">
                        <div class="text-sm-end mt-3 mt-sm-0">
                            <ul class="list-inline mb-0 footer-social-link">
                                <li class="list-inline-item">
                                    <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-facebook-fill"></i>
                                        </div>
                                    </b-link>
                                </li>
                                <li class="list-inline-item">
                                    <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-github-fill"></i>
                                        </div>
                                    </b-link>
                                </li>
                                <li class="list-inline-item">
                                    <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-linkedin-fill"></i>
                                        </div>
                                    </b-link>
                                </li>
                                <li class="list-inline-item">
                                    <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-google-fill"></i>
                                        </div>
                                    </b-link>
                                </li>
                                <li class="list-inline-item">
                                    <b-link href="javascript: void(0);" class="avatar-xs d-block">
                                        <div class="avatar-title rounded-circle">
                                            <i class="ri-dribbble-line"></i>
                                        </div>
                                    </b-link>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </footer>
        <b-button variant="danger" @click="topFunction" class="btn-icon" id="back-to-top">
            <i class="ri-arrow-up-line"></i>
        </b-button>
    </div>
</template>